import React, { FC, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetcher } from "~/lib/fetcher";
import useSWR from "swr";
import styled from "styled-components";
import Sections from "./Sections";
import StepCanvas from "./StepCanvas";
import Details from "./DetailPanes/Details";
import Header from "./Header";
import { Loading } from "~/components/UI/Loading";
import { DEFAULT_LOCALE, EVERYONE_ELSE } from "~/lib/defaults";
import { useHistory } from "~/helpers/useHistory";
import { Outline } from "./Outline/Outline";

const Wrapper = styled.div`
  box-sizing: border-box;
  height: 100vh;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;

  h1 {
    align-items: center;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    justify-content: space-between;
    margin-bottom: 0px;
    margin-top: 5px;
  }
`;

const TemplatedFlowMessage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
    
  span {
      width: 100%;
  }
`;
const Body = styled.div`
  display: flex;
  min-height: 0;
  justify-content: space-between;
  position: relative;
  flex: 1;
  small {
    color: #888;
    font-size: 14px;
  }
`;

const Home: FC = () => {
  const params = useParams();

  const [selectedSection, setSelectedSection] = useState<FlowSection | undefined>(undefined);
  const [selectedStep, setSelectedStep] = useState<{ sectionID: number; stepID: number } | undefined>();
  const [selectedChoice, setSelectedChoice] = useState<number>(-1);
  const [selectedAction, setSelectedAction] = useState<number>(-1);

  const storedLocale = window.sessionStorage.getItem("storedLocale");
  const [selectedLocale, setSelectedLocale] = useState<string>(storedLocale || DEFAULT_LOCALE);

  const [firstLoadComplete, setFirstLoadComplete] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<Roles>(EVERYONE_ELSE);
  const [appViewMode, setAppViewMode] = useState<"canvas" | "outline">("canvas");

  const { data: flow, mutate } = useSWR<FlowIndexResponse>(
    `${process.env.FLOW_BUILDER_API_URL}/flows/${params.flowID}`,
    fetcher,
    {
      refreshInterval: 0,
      revalidateOnFocus: true,
    }
  );

  const { data: languages } = useSWR<Language[]>(`${process.env.FLOW_BUILDER_API_URL}/languages`, fetcher, {
    refreshInterval: 0,
    revalidateOnFocus: true,
  });

  const [history, historyIndex, undo, redo] = useHistory(flow, mutate);

  useEffect(() => {
    window.sessionStorage.setItem("storedLocale", selectedLocale);
  }, [selectedLocale]);

  useEffect(() => {
    if (!firstLoadComplete && flow) {
      const initialStep = flow?.sections[0]?.steps[0]?.id;
      const initialSection = flow?.sections[0]?.id;

      setFirstLoadComplete(true);

      if (params.stepID && params.sectionID) {
        setSelectedSection(flow.sections.find((x: { id: number }) => x.id == Number(params.sectionID)));
        setSelectedStep({ sectionID: Number(params.sectionID), stepID: Number(params.stepID) });
      } else if (initialStep && initialSection) {
        setSelectedSection(flow.sections[0]);
        setSelectedStep({ sectionID: initialSection, stepID: initialStep });
      }
    }

    if (flow && selectedSection) {
      setSelectedSection(flow.sections.find((s: { id: number }) => s.id === selectedSection.id));
    }
  }, [flow, firstLoadComplete]);

  return (
    <>
      {flow ? (
        <Wrapper>
          <Header
            flow={flow}
            mutate={mutate}
            setSelectedLocale={setSelectedLocale}
            selectedLocale={selectedLocale}
            languages={languages || []}
            undo={undo}
            redo={redo}
            historyIndex={historyIndex}
            history={history}
            setSelectedSection={setSelectedSection}
            setSelectedStep={setSelectedStep}
            setAppViewMode={setAppViewMode}
            appViewMode={appViewMode}
          />
          {appViewMode === "canvas" && flow.templateFlowId && (
            <TemplatedFlowMessage>
              <span>This is a templated flow and only Custom Data may be used here.</span>
            </TemplatedFlowMessage>
          )}
          {appViewMode === "canvas"  && !flow.templateFlowId && (
            <Body>
              {firstLoadComplete && (
                <Sections
                  flow={flow}
                  mutate={mutate}
                  selectedSection={selectedSection}
                  setSelectedSection={setSelectedSection}
                  selectedStep={selectedStep}
                  setSelectedStep={setSelectedStep}
                  locale={selectedLocale}
                />
              )}
              <StepCanvas
                mutate={mutate}
                flow={flow}
                section={flow.sections.find((s: { id: number | undefined }) => s.id === selectedStep?.sectionID)}
                step={flow.sections
                  .find((s: { id: number | undefined }) => s.id === selectedStep?.sectionID)
                  ?.steps.find((s: { id: number | undefined }) => s.id === selectedStep?.stepID)}
                viewMode={viewMode}
                setViewMode={setViewMode}
                selectedChoice={selectedChoice}
                setSelectedChoice={setSelectedChoice}
                selectedAction={selectedAction}
                setSelectedAction={setSelectedAction}
                locale={selectedLocale}
                languages={languages || []}
              />
              <Details
                mutate={mutate}
                flow={flow}
                section={
                  flow.sections.find((s: { id: number | undefined }) => s.id === selectedStep?.sectionID) ||
                  selectedSection
                }
                step={flow.sections
                  .find((s: { id: number | undefined }) => s.id === selectedStep?.sectionID)
                  ?.steps.find((s: { id: number | undefined }) => s.id === selectedStep?.stepID)}
                viewMode={viewMode}
                selectedChoice={selectedChoice}
                selectedAction={selectedAction}
                locale={selectedLocale}
              />
            </Body>
          )}
          {appViewMode === "outline" && (
            <Body>
              <Outline flow={flow} languages={languages || []} mutate={mutate} />
            </Body>
          )}
        </Wrapper>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Home;
