import React, { FC, useRef, useState } from "react";
import { Box, TextInput, Layer, Card, CardBody, CardFooter, CheckBox, Select, Tabs, Tab, Spinner } from "grommet";
import { Button } from "~/components/UI";
import styled from "styled-components";
import useSWR, { KeyedMutator } from "swr";
import updateFlow from "~/lib/requests/updateFlow";
import { fetcher } from "~/lib/fetcher";
import updateFlowBackgroundImages from "~/lib/requests/updateFlowBackgroundImages";
import deleteFlowBackgroundImage from "~/lib/requests/deleteFlowBackgroundImage";
import { Alert, FormDown } from "grommet-icons";

interface Props {
  visible: boolean;
  setVisible: (visible: false) => void;
  flow: FlowIndexResponse;
  mutate: KeyedMutator<FlowIndexResponse>;
}

const Form = styled.form`
  font-size: 14px;
`;

const Label = styled(Box)`
  align-items: center;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;

  svg {
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }
`;

const FlowSettings: FC<Props> = ({ visible, setVisible, mutate, flow }) => {
  const [name, setName] = useState(flow.name);
  const [companyName, setCompanyName] = useState(flow.companyName);
  const [disableMusic, setDisableMusic] = useState(flow.disableMusic);
  const [feedbackURL, setFeedbackURL] = useState(flow.feedbackURL);
  const [hideTimeline, setHideTimeline] = useState(flow.hideTimeline);
  const [showBranding, setShowBranding] = useState(flow.showBranding);
  const [skipFeelingSurvey, setSkipFeelingSurvey] = useState(flow.skipFeelingSurvey);
  const [disableChat, setDisableChat] = useState(flow.disableChat);
  const [disableModeSwitching, setDisableModeSwitching] = useState(flow.disableModeSwitching);

  const [durationScalingBase, setDurationScalingBase] = useState(flow.durationScalingBase);

  const [autoHideText, setAutoHideText] = useState(flow.autoHideText);
  const [autoHideButtons, setAutoHideButtons] = useState(flow.autoHideButtons);

  const speakerBackground = useRef<HTMLInputElement>(null);
  const listenerBackground = useRef<HTMLInputElement>(null);

  const [suggestedDurationPrompts, setSuggestedDurationPrompts] = useState(flow.suggestedDurationPrompts);
  const [flashButtons, setFlashButtons] = useState(flow.flashButtons);
  const [playChimes, setPlayChimes] = useState(flow.playChimes);
  const [soloFlowId, setSoloFlowId] = useState(flow.soloFlowId);
  const [templateFlowId, setTemplateFlowId] = useState(flow.templateFlowId);
  const [flowFilter, setFlowFilter] = useState("");

  const { data: flows, error } = useSWR<FlowIndexResponse[]>(`${process.env.FLOW_BUILDER_API_URL}/flows`, fetcher, {
    refreshInterval: 0,
    revalidateOnFocus: true,
  });
  const flowOptions =
    flows
      ?.filter((flow) => flow.name.toLowerCase().includes(flowFilter.toLowerCase()))
      .map((flow) => {
        return { value: flow.id, label: flow.name, selectValue: flow.id.toString() };
      }) || [];

  const saveFlow = () => {
    const speakerFile = speakerBackground.current?.files?.[0];
    const listenerFile = listenerBackground.current?.files?.[0];

    if (speakerFile || listenerFile) {
      updateFlowBackgroundImages(flow.id, speakerFile, listenerFile, mutate);
    }

    updateFlow(
      flow,
      {
        name,
        companyName,
        disableMusic,
        feedbackURL,
        hideTimeline,
        showBranding,
        skipFeelingSurvey,
        durationScalingBase,
        autoHideText,
        autoHideButtons,
        flashButtons,
        playChimes,
        suggestedDurationPrompts,
        soloFlowId,
        templateFlowId,
        disableChat,
        disableModeSwitching,
      },
      mutate
    );
  };

  const deleteBackground = (role: "speaker" | "listener") => {
    deleteFlowBackgroundImage(flow.id, role, mutate);
  };

  if (!visible) return null;
  return (
    <Layer onEsc={() => setVisible(false)} onClickOutside={() => setVisible(false)} animation="fadeIn">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          saveFlow();
          setVisible(false);
        }}
      >
        <Card width="large" background="light-1">
          <Tabs style={{ paddingTop: "10px" }}>
            <Tab title="Flow Settings">
              <CardBody
                pad={{ horizontal: "small", bottom: "small", top: "small" }}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div style={{ marginRight: "20px", width: "50%", borderRight: "1px solid #ddd", paddingRight: "20px" }}>
                  <Label>Flow Name</Label>
                  <TextInput
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    style={{ marginBottom: "30px" }}
                    autoFocus
                  />

                  <Label>Company Name</Label>
                  <TextInput
                    value={companyName || "Warmspace"}
                    onChange={(event) => setCompanyName(event.target.value)}
                    style={{ marginBottom: "30px" }}
                  />

                  <Label>Feedback URL</Label>
                  <TextInput
                    value={feedbackURL || ""}
                    onChange={(event) => setFeedbackURL(event.target.value)}
                    style={{ marginBottom: "30px" }}
                  />

                  <Label>Other settings:</Label>

                  <CheckBox
                    checked={showBranding}
                    label="Show Warmspace branding"
                    onChange={(event) => setShowBranding(event.target.checked)}
                  />

                  <CheckBox
                    checked={disableMusic}
                    label="Disable background music"
                    onChange={(event) => setDisableMusic(event.target.checked)}
                  />

                  <CheckBox
                    checked={hideTimeline}
                    label="Hide timeline"
                    onChange={(event) => setHideTimeline(event.target.checked)}
                  />

                  <CheckBox
                    checked={skipFeelingSurvey}
                    label="Skip feeling survey at the start"
                    onChange={(event) => setSkipFeelingSurvey(event.target.checked)}
                  />

                  <CheckBox
                    checked={disableChat}
                    label="Disable chat"
                    onChange={(event) => setDisableChat(event.target.checked)}
                  />

                  <CheckBox
                    checked={disableModeSwitching}
                    label="Disable mode switching (in person / digital)"
                    onChange={(event) => setDisableModeSwitching(event.target.checked)}
                  />
                </div>
                <div style={{ marginRight: "20px", width: "50%" }}>
                  <Label>Scale suggested step durations based on</Label>
                  <Box direction="row" align="center">
                    <Box width="100px">
                      <TextInput
                        value={durationScalingBase || ""}
                        onChange={(event) => setDurationScalingBase(parseInt(event.target.value))}
                        width=""
                      />
                    </Box>
                    &nbsp; people
                  </Box>

                  <br />

                  <Label>Suggested Duration Prompts</Label>
                  <CheckBox
                    checked={suggestedDurationPrompts}
                    label="Show suggested step duration prompts"
                    onChange={(event) => setSuggestedDurationPrompts(event.target.checked)}
                  />
                  <small>(To prevent people accidentally going to the next step)</small>

                  <br />
                  <br />

                  <Label>When the step&apos;s duration is finished:</Label>
                  <CheckBox
                    checked={playChimes}
                    label="Play a chime sound"
                    onChange={(event) => setPlayChimes(event.target.checked)}
                  />
                  <CheckBox
                    checked={flashButtons}
                    label="Flash the buttons"
                    onChange={(event) => setFlashButtons(event.target.checked)}
                  />

                  <br />

                  <Label>If the mouse isn&apos;t moved for a while:</Label>
                  <CheckBox
                    checked={autoHideText}
                    label="Auto hide text"
                    onChange={(event) => setAutoHideText(event.target.checked)}
                  />
                  <CheckBox
                    checked={autoHideButtons}
                    label="Auto hide buttons"
                    onChange={(event) => setAutoHideButtons(event.target.checked)}
                  />

                  <>
                    <br />

                    <Label>Corresponding solo flow</Label>
                    <Select
                      options={flowOptions}
                      value={soloFlowId?.toString()}
                      labelKey="label"
                      valueKey={{ key: "selectValue", reduce: true }}
                      onSearch={(value) => setFlowFilter(value)}
                      onChange={({ option }) => {
                        setSoloFlowId(option ? option.value : null);
                        setFlowFilter("");
                      }}
                      dropAlign={{ top: "top", left: "left" }}
                      clear={{ position: "top", label: "Clear" }}
                      a11yTitle="Dropdown to select corresponding solo flow"
                      emptySearchMessage="No flows found."
                      disabled={!flows}
                      icon={flows && !error ? <FormDown color="#ffa100" /> : error ? <Alert /> : <Spinner />}
                    />
                    <br />

                    <Label>Template flow</Label>
                    <Select
                      options={flowOptions}
                      value={templateFlowId?.toString()}
                      labelKey="label"
                      valueKey={{ key: "selectValue", reduce: true }}
                      onSearch={(value) => setFlowFilter(value)}
                      onChange={({ option }) => {
                        setTemplateFlowId(option ? option.value : null);
                        setFlowFilter("");
                      }}
                      dropAlign={{ top: "top", left: "left" }}
                      clear={{ position: "top", label: "Clear" }}
                      a11yTitle="Dropdown to select corresponding template flow"
                      emptySearchMessage="No flows found."
                      disabled={!flows}
                      icon={flows && !error ? <FormDown color="#ffa100" /> : error ? <Alert /> : <Spinner />}
                    />
                    <br />
                    {error ? (
                      <>
                        <small>There was an error loading the flows.</small>
                      </>
                    ) : null}
                  </>
                </div>
              </CardBody>
            </Tab>
            <Tab title="In-person Appearance">
              <CardBody
                pad={{ horizontal: "small", bottom: "small", top: "small" }}
                style={{ display: "flex", flexDirection: "row" }}
                height={{ min: "500px" }}
              >
                <div style={{ marginRight: "20px", width: "50%", borderRight: "1px solid #ddd", paddingRight: "20px" }}>
                  <Label>Background for speakers (Users with actions)</Label>

                  <div style={{ display: "flex" }}>
                    {flow.speakerBackground && (
                      <img
                        style={{ marginRight: "10px" }}
                        height="100"
                        src={flow.speakerBackground}
                        alt="Role A background"
                      />
                    )}

                    <div>
                      {!flow.speakerBackground ? (
                        <input
                          id="speaker_background"
                          ref={speakerBackground}
                          type="file"
                          multiple={false}
                          accept=".jpg, .jpeg, .png"
                        />
                      ) : (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            deleteBackground("speaker");
                          }}
                        >
                          Delete file and go back to default
                        </button>
                      )}
                    </div>
                  </div>

                  <br />
                  <br />
                  <Label>Background for listeners (Users without actions)</Label>

                  <div style={{ display: "flex" }}>
                    {flow.listenerBackground && (
                      <img
                        style={{ marginRight: "10px" }}
                        height="100"
                        src={flow.listenerBackground}
                        alt="Role B background"
                      />
                    )}
                    <div>
                      {!flow.listenerBackground ? (
                        <input
                          id="listener_background"
                          ref={listenerBackground}
                          type="file"
                          multiple={false}
                          accept=".jpg, .jpeg, .png"
                        />
                      ) : (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            deleteBackground("listener");
                          }}
                        >
                          Delete file and go back to default
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </CardBody>
            </Tab>
          </Tabs>

          <CardFooter pad="small" background="light-2">
            <Button secondary size="small" onClick={() => setVisible(false)} label="Cancel" />

            <Button
              primary
              size="small"
              label="Save"
              type="submit"
              onClick={() => {
                saveFlow();
                setVisible(false);
              }}
            />
          </CardFooter>
        </Card>
      </Form>
    </Layer>
  );
};

export default FlowSettings;
