import React, { FC } from "react";
import { Box, Select } from "grommet";
import styled from "styled-components";
import { KeyedMutator } from "swr";
import updateStep from "~/lib/requests/updateStep";

const Label = styled(Box)`
  align-items: center;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;

  svg {
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }
`;

interface Props {
  mutate: KeyedMutator<FlowIndexResponse>;
  flow: FlowIndexResponse;
  section: FlowSection;
  step: AnySettingsStep;

  viewMode: Roles;
}

const presenceStepOptions = [
  {value: "video", label: "Video only", selectValue: "video" },
  {value: "in-person", label: "In-person only", selectValue: "in-person" },
  {value: "all", label: "Always run", selectValue: "all" },
]

const sequenceStepOptions = [
  {value: "sequenced", label: "Sequenced flows only", selectValue: "sequenced" },
  {value: "single", label: "Single flow only", selectValue: "single" },
  {value: "all", label: "Always run", selectValue: "all" },
]

const AllStepDetails: FC<Props> = ({ flow, section, step, mutate }) => {

  return (
    <>
      <Label>Presence Visibility:</Label>
      <Select
        options={presenceStepOptions}
        value={step.settings.visibility?.toString() || "all"}
        labelKey="label"
        valueKey={{ key: "selectValue", reduce: true }}
        size="small"
        onChange={({ option }) => {
          //@ts-ignore
          updateStep(mutate, flow.id, section.id, step.id, { settings: { visibility: option.value } });
        }}
        a11yTitle="A drop down to select when this flow should run: in-person, video, or always"
      />
      <br />
      {
        step.settings.visibility == "video" ? "This step will only run during video sessions" :
        step.settings.visibility == "in-person" ? "This step will only run during in-person sessions" :
          "This step will always run for both video and in-person sessions"
      }
      <br />
      <br />


      <Label>Sequence Visibility:</Label>
      <Select
        options={sequenceStepOptions}
        value={step.settings.sequenceVisibility?.toString() || "all"}
        labelKey="label"
        valueKey={{ key: "selectValue", reduce: true }}
        size="small"
        onChange={({ option }) => {
          //@ts-ignore
          updateStep(mutate, flow.id, section.id, step.id, { settings: { sequenceVisibility: option.value } });
        }}
        a11yTitle="A drop down to select when this flow should run: in-person, video, or always"
      />
      <br />
      {
        step.settings.sequenceVisibility == "sequenced" ? "This step will only run if this flow is part of a sequence of flows" :
        step.settings.sequenceVisibility == "single" ? "This step will only run if it's run alone and NOT part of a sequence" :
          "This step will always run for both single flows and sequenced flows"
      }
      <br />
      <br />
    </>
  );
};

export default AllStepDetails;
